import { Fragment, useState, useEffect } from 'react';

// External components
import styled from 'styled-components';

// Internal components
import CookieBanner from './components/CookieBanner';

// Interface
import Icon from './components/icons';
import { theme, GlobalFonts, LightenDarkenColor, Opacity } from './interface';

// Assets
import background from './assets/welcome-screen.jpg';
import Button from './interface/button';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.palette.grey[700]};

  & .appbar {
    height: 70px;
    background-color: ${theme.palette.primary.dark};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: ${theme.zIndex.appBar};
    overflow: hidden;

    & > svg {
      fill: ${theme.palette.common.white};
      height: 40px;
      width: 40px;
      margin: 15px;
    }
  }

  & .site-body {
    flex-grow: 1;
    overflow: auto;
    background-color: #3c3c3c;
    background-image: url(${background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: ${theme.breakpoints.md}px) {
      display: flex;
      flex-grow: 1;
      margin-bottom: initial;
    }

    & .content {
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 1.6em;
      background-color: ${theme.palette.grey[100]};
      font-size: 20px;
      font-family: ${theme.typography.subtitle1.fontFamily};
      color: ${theme.palette.common.black};
      font-weight: 100;
      box-sizing: border-box;

      @media screen and (min-width: ${theme.breakpoints.md}px) {
        border-radius: 4px;
        box-shadow: ${theme.shadows[1]};
        max-width: min(80%, 400px);
        height: auto;
      }

      p {
        margin: 0 0 1em 0;
      }

      .button {
        margin: 0;
      }
    }
  }

  & .policy-buttons {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 1.3em 2.6em;
    z-index: ${theme.zIndex.snackbar};
    border-radius: 4px 0 0 0;
    background-color: ${Opacity(theme.palette.common.black, 70)};
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: space-evenly;

    @media screen and (min-width: ${theme.breakpoints.md}px) {
      background-color: ${Opacity(theme.palette.common.black, 30)};
      width: auto;
    }

    & .policy-buttons__button {
      font-size: 12px;
      font-family: ${theme.typography.subtitle1.fontFamily};
      text-transform: uppercase;
      color: ${LightenDarkenColor(theme.palette.common.white, -15)};

      &:hover {
        color: ${theme.palette.common.white};
      }

      &:nth-child(2) {
        @media screen and (min-width: ${theme.breakpoints.md}px) {
          margin-left: 3em;
        }
      }
    }
  }
`;

/**
 * TODO:
 *
 * -[x] make cookie box full width on mobile
 * -[x] add arrow icon beside title
 * -[x] update server name to use .env
 * -[x] check data from the server works correctly
 * -[x] add cookie policy links
 * -[x] set target for login to self
 * -[x] IE tests
 *
 */

function App() {
  const [content, setContent] = useState('');
  const [count, setCount] = useState(5);

  // get text from noscript and start countdown
  useEffect(() => {
    const text = document.querySelector('noscript');
    setContent(text.textContent);

    let counter = window.setInterval(() => {
      setCount((c) => c - 1);
    }, 1000);

    return () => {
      window.clearInterval(counter);
    };
  }, []);

  // when count is 0 automatically redirect
  useEffect(() => {
    if (count > 0) return;
    window.open(process.env.REACT_APP_SITE_URL, '_self');
  }, [count]);

  return (
    <Fragment>
      <GlobalFonts />
      <StyledDiv>
        <div className="appbar">
          <Icon type="logo" />
        </div>
        <div className="site-body">
          <div className="content">
            <p>{content}</p>
            <p>You will be automatically redirect in 5 seconds.</p>
            <Button onClick={() => setCount(0)}>Redirect ({count})</Button>
          </div>
        </div>

        <div className="policy-buttons">
          <a
            href="https://www.rolls-royce.com/site-services/portal-2-power.aspx"
            target="_blank"
            rel="noreferrer"
            className="policy-buttons__button"
          >
            Liability and Privacy
          </a>
          <a
            href="https://www.rolls-royce.com/site-services/use-of-cookies.aspx"
            target="_blank"
            rel="noreferrer"
            className="policy-buttons__button"
          >
            Cookie Policy
          </a>
        </div>
      </StyledDiv>

      <CookieBanner>
        We use cookies on our website to give you the most relevant experience by remembering your preferences and
        repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.
      </CookieBanner>
    </Fragment>
  );
}

export default App;
