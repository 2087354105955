// polyfills
import "react-app-polyfill/ie9";
import 'react-app-polyfill/ie11';
import "jspolyfill-array.prototype.findIndex";
import "element-matches-polyfill";
import 'polyfill-array-includes';
import 'document.contains';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

