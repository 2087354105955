import { useMemo } from 'react';

export default function Icon({ type, ...props }) {
  return useMemo(() => {
    switch (type) {
      case 'logo':
        return (
          <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.2 180.9">
            <path d="M.18 1.34c0 .9.6 1.6 1.3 1.6 1.8 0 5.3 3.9 6.2 6.8.4 1.3.7 32.6.7 69.6 0 60.8-.1 67.6-1.6 70.8-.9 1.9-2.8 4.1-4.1 4.8-2.4 1.3-3.3 2.9-1.7 3.1.4.1 7.6.2 15.9.3 11.8.1 15.4-.1 15.8-1.2.2-.7-.6-1.7-2-2.2-1.3-.5-3.2-2.4-4.1-4.2-1.5-2.9-1.8-6.9-1.9-31.6l-.1-28.2 7.5-.1c4.2-.1 8.5-.3 9.6-.4 5.5-.6 12.5-1.7 17.2-2.7 6.5-1.4 7.2-.9 13.1 9.5 7.7 13.4 12.6 29.5 13.8 45.3.6 8 .6 8.1-2.5 11.6-1.7 2-2.8 3.8-2.4 4 1.8.9 32.7.2 33-.7.1-.6-1.3-2-3.2-3.2-4.9-3.2-6-5.3-9.5-18.3-5.5-20.4-12.9-37-21-47.8-2.2-2.9-4-5.4-4-5.6s2.5-1.8 5.6-3.6c21.4-12 29.5-35.1 19.4-54.9-5.1-10-13.5-17-25.9-21.5-5.1-1.8-8.7-2-40.3-2.4C3.78-.16.18-.06.18 1.34zm68.8 10.3c11.7 4.7 18.7 14 19.6 26.2 1.6 22.6-13.8 38.2-42.9 43.6-7.2 1.3-19.7 1.8-20.4.7-.5-.8-.7-66.2-.2-72.1.1-1.1 3.6-1.2 19.4-.9 16.5.4 19.9.7 24.5 2.5z" />
            <path d="M37.58 22.84c-.9.9-.3 1.8 2 2.9 5.6 2.5 6.1 4.8 6.2 27.4 0 11.4.3 21.1.7 21.4.8.8 9-1.3 12.9-3.3l2.8-1.3.2-19.3.3-19.2 9.3.1c10.3.1 10.3.1 6-6.1l-2-3h-19c-10.4 0-19.1.2-19.4.4zM108.58 25.54c.4 1.1 1.2 4 1.8 6.5.8 3.3 1.9 5.1 4 6.5 6.5 4.2 11.8 15.3 11.8 24.8 0 15-8.6 27.5-24.1 34.9l-6.7 3.2 1.6 3.7c1 2.2 2.4 3.8 3.4 3.8 2.3 0 8.1 8.3 12.8 18.3 5.3 11.4 8.9 25.1 9.7 36.9.5 9.4.5 9.7-2.1 12.3-1.4 1.4-2.6 3-2.6 3.5 0 .6 6.7 1 16.5 1 17.7 0 19.5-.6 13.2-4.5-4.3-2.7-7.2-8.3-9.7-18.9-4.1-16.9-12.8-36.6-21.1-47.5-2.2-2.9-3.9-5.4-3.7-5.6.2-.1 2.8-1.5 5.8-3.1s8-5.4 11.1-8.5c14.3-14.4 16.6-34.6 5.8-50.5-5.6-8.2-16.5-16-24.8-17.9-3-.6-3.3-.5-2.7 1.1zM57.68 94.04c-1.6.4-5 1-7.5 1.3l-4.5.6v37.3c-.1 34.6-.2 37.4-1.9 40.4-1.1 1.7-3 3.6-4.3 4.2-5.3 2.4-2.1 3.1 14.2 3.1 9.1 0 16.5-.4 16.5-.9s-1.1-1.6-2.5-2.5c-5.1-3.3-5.4-5.2-5.6-35-.1-15.2 0-27.8.2-28.1.3-.3 5.7-1.1 10.1-1.4 1.1-.1-1.3-5.4-3-6.8-.8-.7-2.7-.9-4.3-.6-3.1.6-3.4-.1-2.9-8.3.2-3.8-.5-4.3-4.5-3.3z" />
          </svg>
        );
      default:
        return (
          <svg {...props} height="24" viewBox="0 0 24 24" width="24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
          </svg>
        );
    }
  }, [props, type]);
}
