import { createContext } from 'react';
import { themeObject, colorList } from './index.interface';
import GlobalFonts from './fonts';

export const theme: themeObject = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  direction: 'ltr',
  palette: {
    common: {
      black: '#333333',
      white: '#fff',
    },
    grey: {
      100: '#fafafa',
      200: '#f1f1f1',
      300: '#efeff4',
      400: '#e1e1e6',
      500: '#C8C7CC',
      600: '#8a8a8f',
      700: '#68686b',
      disabled: '#C8C7CC',
    },
    primary: {
      light: '#4F98FF',
      main: '#156DFB',
      dark: '#10069F',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7986cb',
      main: '#006dff',
      dark: '#303f9f',
      contrastText: '#fff',
    },
    error: {
      light: '#FF7140',
      main: '#D83F11',
      dark: '#9F0000',
      contrastText: '#fff',
    },
    warning: {
      light: '#fa4692',
      main: '#e21d60',
      dark: '#880e4f',
      contrastText: '#fff',
    },
    info: {
      light: '#58009c',
      main: '#8c2ace',
      dark: '#58009c',
      contrastText: '#fff',
    },
    success: {
      light: '#C4DB3E',
      main: '#819C00',
      dark: '#034f00',
      contrastText: '#fff',
    },
    action: {
      active: {
        light: '#7986cb',
        main: '#4F98FF',
        dark: '#303f9f',
        contrastText: '#fff',
      },
      hover: {
        light: '#7986cb',
        main: '#006dff',
        dark: '#303f9f',
        contrastText: '#fff',
      },
      focus: {
        light: '#7986cb',
        main: '#4F98FF',
        dark: '#303f9f',
        contrastText: '#fff',
      },
    },
  },
  shadows: {
    1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  },
  typography: {
    fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    lineHeight: 1.1,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '40px',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '28px',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: 1.4,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: '"RR Pioneer", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 4,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: '150ms',
      shorter: '200ms',
      short: '250ms',
      standard: '300ms',
      complex: '375ms',
      enteringScreen: '225ms',
      leavingScreen: '195ms',
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1200,
    drawer: 1100,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
};

// helper function for colour conversion
function _padZero(len: number = 2, s: string) {
  const pLen = len;
  while (s.length < pLen) s = '0' + s;

  return s;
}

// object of colours and functions for colour conversion
const _colors: colorList = {
  colornames: {
    aqua: '#00ffff',
    black: '#000000',
    blue: '#0000ff',
    fuchsia: '#ff00ff',
    gray: '#808080',
    green: '#008000',
    lime: '#00ff00',
    maroon: '#800000',
    navy: '#000080',
    olive: '#808000',
    purple: '#800080',
    red: '#ff0000',
    silver: '#c0c0c0',
    teal: '#008080',
    tomato: '#ff6347',
    white: '#ffffff',
    yellow: '#ffff00',
  },
  toRgb: (c: string) => {
    const pC = parseInt('0x' + _colors.toHex(c).substring(1));
    const array = [(pC >> 16) & 255, (pC >> 8) & 255, pC & 255];

    return 'rgb(' + array.join(',') + ')';
  },
  toHex: (c: string) => {
    let tem;
    let i = 0;
    let pC = c ? c.toString().toLowerCase() : '';
    if (/^#[a-f0-9]{3,6}$/.test(pC)) {
      if (pC.length < 7) {
        var A = pC.split('');
        pC = A[0] + A[1] + A[1] + A[2] + A[2] + A[3] + A[3];
      }

      return pC;
    }
    if (/^[a-z]+$/.test(pC)) {
      return _colors.colornames[pC] || '';
    }
    let array = pC.match(/\d+(\.\d+)?%?/g) || [];
    if (array.length < 3) return '';
    array = array.slice(0, 3);
    while (i < 3) {
      tem = array[i];
      if (tem.indexOf('%') !== -1) {
        tem = Math.round(parseFloat(tem) * 2.55);
      } else tem = parseInt(tem);
      if (tem < 0 || tem > 255) array.length = 0;
      else array[i++] = _padZero(2, tem.toString(16));
    }
    if (array.length === 3) return '#' + array.join('').toLowerCase();

    return '';
  },
};

// convert colour and return brightness value
function _getLuminance(color: string) {
  const rgbColor = _colors.toRgb(color);
  const rgbColorSplit = rgbColor.substring(4, rgbColor.length - 1).split(',');

  const [r, g, b] = rgbColorSplit.map((value: number) => {
    const channel = value / 255;

    return channel <= 0.03928 ? channel / 12.92 : ((channel + 0.055) / 1.055) ** 2.4;
  });

  return parseFloat((0.2126 * r + 0.7152 * g + 0.0722 * b).toFixed(3));
}

// helper function to cycle through theme object only updating changed fields
function _deepSetObject(newTheme: any, originalTheme: themeObject): themeObject {
  const combinedTheme: any = Object.assign({}, originalTheme);
  for (const key of Object.keys(newTheme) as (keyof themeObject)[]) {
    if (typeof newTheme[key] === 'object' && !Array.isArray(newTheme[key])) {
      combinedTheme[key] = _deepSetObject(newTheme[key], combinedTheme[key]);
    } else {
      combinedTheme[key] = newTheme[key];
    }
  }

  return combinedTheme as themeObject;
}

// Modify theme with updates
const themeModify = (newTheme: themeObject): themeObject => {
  // catch invalid input
  if (!newTheme || typeof newTheme !== 'object' || Array.isArray(newTheme)) return theme;

  return _deepSetObject(newTheme, theme);
};

/**
 * Check 2 colours to get contrast and return best contrast colour
 * from foreground, black or white
 *
 * @param {String} foreground colour used for foreground
 * @param {String} background colour used for background
 */
export const checkContrast = (foreground: string, background: string) => {
  const threshold = 4.5;
  const bg = _getLuminance(background);
  const fg = _getLuminance(foreground);

  const ratio = fg < bg ? 1 / ((fg + 0.05) / (bg + 0.05)) : (fg + 0.05) / (bg + 0.05);

  /* If it passes spec return it */
  if (ratio >= threshold) return foreground;
  /* If it fails check black and white */
  const white = _getLuminance(theme.palette.common.white);
  const black = _getLuminance(theme.palette.common.black);

  const wRatio = white < bg ? 1 / ((white + 0.05) / (bg + 0.05)) : (white + 0.05) / (bg + 0.05);
  const bRatio = black < bg ? 1 / ((black + 0.05) / (bg + 0.05)) : (black + 0.05) / (bg + 0.05);

  // Issue warning about the contrast issue
  console.warn(
    `checkContrast: text has failed contrast check\n   foreground: ${foreground}\n   background: ${background}\n   ratio: ${ratio.toFixed(
      3,
    )}`,
  );

  // return highest value
  if (wRatio < ratio && bRatio < ratio) return foreground;
  if (wRatio > bRatio) {
    return theme.palette.common.white;
  }

  return theme.palette.common.black;
};

/**
 * Lighten or darken a given colour by a percentage.
 *
 * @param {String} color colour to be updated
 * @param {Number} percentage Percentage to shift by, can be positive or negative
 */
export const LightenDarkenColor = (color: string, percentage: number) => {
  const rgbColor = _colors.toRgb(color);
  const [r, g, b] = rgbColor.substring(4, rgbColor.length - 1).split(',');

  const constrainColor = (colour: number) => (colour > 255 ? 255 : colour < 0 ? 0 : Math.floor(colour));

  const amt = Math.round(2.55 * percentage);
  const newR = constrainColor(parseInt(r) + amt);
  const newG = constrainColor(parseInt(g) + amt);
  const newB = constrainColor(parseInt(b) + amt);

  const outputColor = `rgb(${newR},${newG},${newB})`;

  return _colors.toHex(outputColor);
};

/**
 * Convert colour to RGBA with set alpha.
 *
 * @param {String} color colour to be updated
 * @param {Number} percentage Percentage of opacity where 0 is transparent and 100 is opaque
 */
export const Opacity = (color: string, percentage: number) => {
  const rgbColor = _colors.toRgb(color);
  const [r, g, b] = rgbColor.substring(4, rgbColor.length - 1).split(',');
  const a = percentage / 100;

  const outputColor = `rgba(${r},${g},${b},${a})`;

  return outputColor;
};

/**
 * Convert colour into an RGB object for use in JS
 *
 * @param {String} color colour to be updated
 *
 * @returns {Object} {r: red, g: green, b: blue, }
 */
export const SplitRGB = (color: string) => {
  const rgbColor = _colors.toRgb(color);
  const rgbColorSplit = rgbColor.substring(4, rgbColor.length - 1).split(',');

  return {
    r: parseInt(rgbColorSplit[0]),
    g: parseInt(rgbColorSplit[1]),
    b: parseInt(rgbColorSplit[2]),
  };
};

/**
 * Convert colour into a decimal number (for use with three js)
 *
 * @param {String} color colour to be converted
 *
 * @returns {Number} decimal number
 */
export const hexToDecimal = (color: string) => {
  return parseInt(color.substring(1), 16);
};

export default themeModify;

export const SocketContext = createContext(null);

export { GlobalFonts };
